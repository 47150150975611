<template>
    <div class="search_filter_wrap" style="margin-top: 27px">
        <div class="search_item_wrap">
            <div class="filter_category_box">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="category_select"
                           :popper-append-to-body="false"
                >
                    <el-option :value="'ina'" :label="$t('subject')"></el-option>
                    <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                    <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
                </el-select>
            </div>
            <div class="search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="search_btn" @click="setFilterFunc(searchInputOption,searchInput)"><span>{{ $t('search') }}</span>
                    <img src="@/assets/image/main/search_btn.png"></button>
            </div>
            <div class="end_filter">
                <el-popover
                    popper-class="top_700 itemFilterNone"
                    placement=""
                    width="435"
                    trigger="click"
                    :open-delay="300"
                    :offset="0"
                    :append-to-body="false"
                    :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
                    <el-button slot="reference" class="filter_btn two"></el-button>
                    <div class="popup_tail"></div>
                    <div class="popup_bg">
                        <div class="popup_inner">
                            <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
                            <div class="inner_middle">
                                <div class="sub_title">{{$t('own2_datetime')}}</div>
                                <div class="price_wrap">
                                    <el-date-picker
                                        v-model="filterPossessDate"
                                        type="daterange"
                                        range-separator="~"
                                        value-format="yyyy-MM-dd"
                                        @change="setFilterFunc('po', filterPossessDate)"
                                        start-placeholder="YYYY-MM-DD"
                                        end-placeholder="YYYY-MM-DD">
                                    </el-date-picker>
                                </div>
                                <div class="price_wrap first_top mt5">
                                    <div class="sub_title">{{$t('detections_number')}}</div>
                                    <el-input v-model="filterMatch[0]" @keypress.native.enter="changeFilter()"
                                              class="el_price_input el_input_num" type="text"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> {{ $t('count') }}</span>
                                    <span class="first_input_step">~</span>
                                    <el-input v-model="filterMatch[1]" @keypress.native.enter="changeFilter()"
                                              class="el_price_input el_input_num" type="text"
                                              oninput="value = value.replace(/[^0-9.]/g,'')"
                                    />
                                    <span> {{ $t('count') }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrapper_bottom" @click="changeFilter()">
                        <button class="">{{$t('apply2')}}</button>
                    </div>
                </el-popover>
            </div>
        </div>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";

const {filterConfig} = itemUtil;

export default {
    name: "ItemFilterDetectionLayout",
    mixins: [],
    components: {},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return {
            searchInputOption: 'ina',
            searchInput: '',
            filterPossessDate: [],
            filterMatch: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        openModal(name) {
            this.$modal.show(name, {modalLayout: 'ModalFilterDefaultLayout'});
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        changeFilter() {
            /*if (this.filterMatch[0] !== '' && this.filterMatch[0] > this.filterMatch[1]) {
                return
            }*/
            this.setFilterFunc('ma', this.filterMatch);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
